<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>SWP Items</strong>
          </CCardHeader>
          <CRow>
            <CCol md="10">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Title *"
                    v-model="item.title"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Title Name'"
                    @blur="$v.item.title.$touch()"
                    :is-valid="
                      $v.item.title.$dirty ? !$v.item.title.$error : null
                    "
                  />

                  <!-- <div class="form-group row">
						   <label for="example-text-input text-right" class="col-sm-4 col-form-label">Popular *</label>
						   <div class="col-sm-6" :disabled="mode.viewMode" >  
							<input   type="radio" v-model="item.popular" value=true >Yes
  							<input  type="radio" v-model="item.popular" value=false>No
						   </div>
					    </div> -->
                  <div class="form-group row">
                    <label
                      for="example-text-input text-right"
                      class="col-sm-3 col-form-label"
                    >
                      Popular
                    </label>
                    <div class="col-sm-9">
                      <select
                        class="form-control"
                        v-model="item.popular"
                        :disabled="mode.viewMode"
                      >
                        <option disabled value="">--Select One Option--</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="example-text-input text-right"
                      class="col-sm-3 col-form-label"
                    >
                      Select File *
                    </label>
                    <div class="col-sm-9">
                      <CInputFile
                        horizontal
                        type="file"
                        :disabled="mode.viewMode"
                        @change="uploadImage"
                        :invalid-feedback="'Please Upload the File'"
                        @blur="$v.item.fileId.$touch()"
                        :is-valid="
                          $v.item.fileId.$dirty ? !$v.item.fileId.$error : null
                        "
                      />
                      <div>
                        <!-- {{ currentPage }} / {{ pageCount }} -->
                        <pdf
                          :src="imageUrl(item.fileId)"
                          @num-pages="pageCount = $event"
                          @page-loaded="currentPage = $event"
                        ></pdf>
                        <!-- <pdf
            v-for="i in numPages"
            :key="i"
            :src="imageUrl(item.fileId)"
            :page="i"
            style="display: inline-block; width: 25%"
        ></pdf> -->
                      </div>
                    </div>
                  </div>
                  <!-- <CRow>
                    <CCol md="3"></CCol>
                    <CCol md="9" align="center">
                      <CCard v-if="item.fileId && !$v.item.fileId.$error" style="margin-top: 20px">
                      
                        <CCardBody align="center">
                     <CButton color="danger"  style="float: right" @click="removeimage() ">
                          <CIcon   name="cilXCircle" description="Delete"  ></CIcon> 
                         </CButton> 
                          <CImg
                            v-if="item.fileId"
                            :src="imageUrl(item.fileId)"
                            height="180px"
                            alt="Responsive image"
                            :disabled="mode.viewMode"
                          > </CImg>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow> -->
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="Save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import pdf from "vue-pdf";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
//var loadingTask = pdf.imageUrl(fileId);
export default {
  name: "SWP Item",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      swpItemId: "",
      categoryId: "",
      item: {
        swpItemId: "0",
        categoryId: this.$route.params.categoryId,
        title: "",
        popular: "",
        fileId: "",
      },
      //  src: imageUrl(),
      //    numPages: undefined,
      horizontal: { label: "col-3", input: "col-9" },
      currentPage: 0,
      pageCount: 0,
    };
  },

  validations: {
    item: {
      title: {
        required,
      },
      fileId: {
        required,
      },

      // popular:{
      //  required,
      // }
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.categoryId = this.$route.params.categoryId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.swpItemId = this.$route.params.swpItemId;
      this.categoryId = this.$route.params.categoryId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.swpItemId = this.$route.params.swpItemId;
      this.categoryId = this.$route.params.categoryId;
      this.getUser();
    }
  },
  components: { datetime, pdf },
  methods: {
    imageUrl(fileId) {
      console.log(fileId);
      return process.env.VUE_APP_API_HOST + "/emapp/file/" + fileId;
    },
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({
        name: "SWP Items",
        params: { categoryId: this.$route.params.categoryId },
      });
    },
    uploadImage(files, event) {
      const URL = process.env.VUE_APP_API_HOST + "/emapp/file/upload";
      console.log("uploadImage", URL);
      console.log("files", files);
      console.log("event", event);
      let fdata = new FormData();
      fdata.append("file", files[0]);

      // fdata.append("courseId","courseId");
      fdata.append("fileId", "fileId");

      fetch(URL, {
        method: "POST",
        body: fdata,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("image upload response > ", data);
          // this.service.image = data.data.fileId;

          this.item.fileId = data.data.fileId;
        });
    },

    Save() {
      this.$v.item.$touch();
      if (this.$v.item.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.item));
      } else {
        //  fetch(
        //     process.env.VUE_APP_API_HOST +
        //       "/emapp/web/secure/swp/category/item/save/" +
        //       this.categoryId,
        //     {
        //       method: "POST",
        //       headers: {
        //         "Content-type": "application/json",
        //       },
        //       body: JSON.stringify(this.item),
        //     }
        //   )

        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/swp/category/item/save/" +
            this.categoryId,
          this.item
        ).then((item) => {
          console.log(JSON.stringify(item));
          // this.$router.push({ name: "Groups" });
          this.$router.push({
            name: "SWP Items",
            params: { categoryId: this.$route.params.categoryId },
          });
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/swp/item/" +
          this.swpItemId
      )
        .then((response) => response.json())
        .then((data) => (this.item = data));
    },
    // getRoles() {
    // fetch(process.env.VUE_APP_API_HOST +"/web/secure/meta/roles")
    //     .then((response) => response.json())
    //     .then( (data)  => (this.roles = data) );
    // },
  },
  mounted() {
    // this. getRoles();
    //   this.src.promise.then(pdf => {
    //           this.numPages = pdf.numPages;
    // });
  },
};
</script>
